/*global History, Modernizr, FastClick, alert*/

window.main = window.main || {};

var $htmlbody = $('html, body');
var $body = $('body');
var $header = $('.js-header');
var $main = $('.js-main');

var tickerHeader = $('.c-ticker--header');
var tickerBanner1 = $('.c-ticker--banner--1');
var tickerBanner2 = $('.c-ticker--banner--2');
var tickerBanner3 = $('.c-ticker--banner--3');

var $pageLink = $('.js-page-link');
var pageLink = '.js-page-link';
var $pageItem = $('.js-page-item');
var pageItem = 'js-page-item';
var clickedOverlay;

var $btnSoundOn = $('.js-btn-sound-on');
var $btnSoundOff = $('.js-btn-sound-off');

var didScroll = false;
var currentPos;
var currentItem;
var lastScrollPos = 0;

var ww = $(window).width();
var wh = $(window).height();
var dw = $(document).width();
var dh = $(document).height();

var brLarge = 1440;
var brMedium = 768;
var brSmall = 520;

main.init = function() {

  main.checkOnScroll($(document).scrollTop());
  main.initEvents();
  main.setLayout();

  $('.c-gif__image').unveil(200, function() {
      $(this).addClass('is-loaded');
  });

  main.initAudio();
  main.initTickers();
  main.initRepaint();

  setTimeout(function() {
    $('.preloader').addClass('is-loaded');
  }, 400);

  $(window).on('load', function() {
    main.setLayout();
  });

  $(window).on('resize', function() {
    main.setLayout();
  });

};

/*
main.initAudio = function() {

  var firstTimeFired = true;

  function startPlayback() {
    return document.querySelector('#audio-muffled').play();
  }

  console.log('Attempting to play automatically...');

  startPlayback().then(function() {
    console.log('The play() Promise fulfilled! Rock on!');
    $("#audio-full").trigger("play");

    $(".js-toggle-sound").on('click', function() {
        $(this).toggleClass('is-muted');
        $("#audio-muffled").prop("muted",!$("#audio-muffled").prop("muted"));
        $("#audio-full").prop("muted",!$("#audio-full").prop("muted"));
    });

  }).catch(function(error) {
    console.log('The play() Promise rejected!');
    console.log('Use the Play button instead.');
    console.log(error);

    $(".js-toggle-sound").addClass('is-muted');
    $(".js-toggle-sound").on('click', function() {
        if(firstTimeFired) {
          $(this).toggleClass('is-muted');
          $("#audio-muffled").trigger("play");
          $("#audio-full").trigger("play");
        } else {
          $(this).toggleClass('is-muted');
          $("#audio-muffled").prop("muted",!$("#audio-muffled").prop("muted"));
          $("#audio-full").prop("muted",!$("#audio-full").prop("muted"));
        }
        firstTimeFired = false;
    });

  });

  $("#audio-muffled").prop("volume",1);
  $("#audio-full").prop("volume",0);

};
*/

main.initAudio = function() {

  $btnSoundOn.on('click', function(e) {
    e.preventDefault();
    $('.c-popup').addClass('is-triggered');
    $body.removeClass('no-scroll');
    $("#audio-muffled").trigger('play');
    $("#audio-full").trigger('play');
  });

  $btnSoundOff.on('click', function(e) {
    e.preventDefault();
    $('.c-popup').addClass('is-triggered');
    $body.removeClass('no-scroll');
    $(".js-toggle-sound").addClass('is-muted');
    $("#audio-full").prop('muted', true);
    $("#audio-muffled").prop('muted', true);
    $("#audio-muffled").trigger('play');
    $("#audio-full").trigger('play');
  });

  $(".js-toggle-sound").on('click', function() {
    $(this).toggleClass('is-muted');
    $("#audio-muffled").prop("muted",!$("#audio-muffled").prop("muted"));
    $("#audio-full").prop("muted",!$("#audio-full").prop("muted"));
  });

};


main.setVolume = function(value) {
  $("#audio-muffled").prop("volume",1-value);
  $("#audio-full").prop("volume",value);
};

main.initEvents = function() {

  $body.on('click', pageLink, function(e) {
    e.preventDefault();
    clickedPage = $(this).attr('href');
    main.togglePage(clickedPage);
  });

};

main.initRepaint = function() {

  console.log('repaint');
  //Read a size property;

  //tickerHeader.css({'display':'none'});
  tickerHeader.outerHeight();
  //tickerHeader.css({'display':'block'});

  //tickerBanner1.css({'display':'none'});
  tickerBanner1.outerHeight();
  //tickerBanner1.css({'display':'block'});

  //tickerBanner2.css({'display':'none'});
  tickerBanner2.outerHeight();
  //tickerBanner2.css({'display':'block'});

  //tickerBanner3.css({'display':'none'});
  tickerBanner3.outerHeight();
  //tickerBanner3.css({'display':'block'});


};

main.initTickers = function() {

  $('.c-ticker--header').addClass('js-banner-header-animation');
  $('.c-ticker--banner--1').addClass('js-banner-1-animation');
  $('.c-ticker--banner--2').addClass('js-banner-2-animation');
  $('.c-ticker--banner--3').addClass('js-banner-3-animation');

  setTimeout(function(){

    if(ww >= brMedium) {
      $('.c-ticker--banner--1').css({'-webkit-animation-play-state':'paused'});
      $('.c-ticker--banner--1').css({'animation-play-state':'paused'});
      $('.c-ticker--banner--2').css({'-webkit-animation-play-state':'paused'});
      $('.c-ticker--banner--2').css({'animation-play-state':'paused'});
      $('.c-ticker--banner--3').css({'-webkit-animation-play-state':'paused'});
      $('.c-ticker--banner--3').css({'animation-play-state':'paused'});

      $('.js-banner-1-animation').hover(
        function() {
          $( this ).css({'-webkit-animation-play-state':'running'});
          $( this ).css({'animation-play-state':'running'});
          main.initRepaint();
        }, function() {
          $( this ).css({'-webkit-animation-play-state':'paused'});
          $( this ).css({'animation-play-state':'paused'});
          main.initRepaint();
        }
      );

      $('.js-banner-2-animation').hover(
        function() {
          $( this ).css({'-webkit-animation-play-state':'running'});
          $( this ).css({'animation-play-state':'running'});
          main.initRepaint();
        }, function() {
          $( this ).css({'-webkit-animation-play-state':'paused'});
          $( this ).css({'animation-play-state':'paused'});
          main.initRepaint();
        }
      );

      $('.js-banner-3-animation').hover(
        function() {
          $( this ).css({'-webkit-animation-play-state':'running'});
          $( this ).css({'animation-play-state':'running'});
          main.initRepaint();
        }, function() {
          $( this ).css({'-webkit-animation-play-state':'paused'});
          $( this ).css({'animation-play-state':'paused'});
          main.initRepaint();
        }
      );
    }

  },100);

};

main.setLayout = function() {

  ww = $(window).width();
  wh = $(window).height();
  dw = $(document).width();
  dh = $(document).height();

};

main.togglePage = function(page) {

  $(page).toggleClass('is-visible');

};

main.scrollTo = function(href) {

  $('html:not(:animated), body:not(:animated)').animate({
    scrollTop: $(href).position().top - $header.outerHeight()
  }, 660, 'easeInOutQuart');

  $htmlbody.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function() {
    $htmlbody.stop();
  });

};

main.scrollToTop = function() {

  $htmlbody.animate({
    scrollTop: 0
  }, 320);

};

main.checkOnScroll = function(scrollPosition) {

  //Xvalue f.e. 1800 - 1440 = 360
  //Percentage = 360/1440 = 0.25 (this is the value you want for the volume)
  var value = scrollPosition/wh;
  main.setVolume(value);

  scrollLastPosition = scrollPosition;

};

//On Scroll
$(document).on('scroll', function(event) {

  didScroll = true;
  main.checkOnScroll($(this).scrollTop());

});

setInterval(function() {

  if (didScroll) {
    didScroll = false;
  }

}, 200);


$(main.init);
